<template>
  <div class="apply-success">
    <div class="icon">
      <icon-svg class="main-color success-svg" icon-class="yi_icon_xuanze"/>
    </div>
    <p class="title">您的申请已提交</p>
    <p class="tip">请耐心等待</p>
    <div class="btn" @click="lookApply">
      查看申请
    </div>
  </div>
</template>
<script>
import {baseUrl} from "../../utils/env";
import { mapState } from "vuex";
export default {
  name: "apply-success",
  data() {
    return {
      order_id:this.$route.query.order_id
    };
  },
   computed: {
    ...mapState({
      type: state => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      pay_password: state => !!state.userInfo.pay_password //是否设置支付密码
    })
  },
  methods:{
    lookApply(){
      if(this.type==4){
         window.location.replace(`${baseUrl}/#/check_order_details?order_id=${this.order_id}`);
       // window.location.replace(`${window.location.href.split("#")[0]}#/check_order_details?order_id=${this.order_id}`);
        
      }else{
        // window.location.replace(`${window.location.href.split("#")[0]}#/order_list?key=AFTER_SALE`);
         window.location.replace(`${baseUrl}/#/order_list?key=AFTER_SALE`);
      }
      // window.location.replace(`${baseUrl}/#/order_list?key=AFTER_SALE`);
    },
  },
}
</script>
<style scoped lang="less">
@import "~@/common/less/variable.less";

.apply-success {
  width: 100%;
  height: 100%;
  background: #fff;
  padding-top: 1rem;
  box-sizing: border-box;

  .icon {
    width: 2.2rem;
    height: 2.2rem;
    margin: 0 auto 0.64rem;

    .success-svg {
      width: 2.2rem !important;
      height: 2.2rem !important;
    }
  }

  .title {
    font-size: 0.32rem;
    color: #333333;
    text-align: center;
    margin-bottom: 0.2rem;
  }

  .tip {
    font-size: 0.28rem;
    color: #999999;
    text-align: center;
    margin-bottom: 0.6rem;
  }

  .btn {
    width: 7.02rem;
    height: 0.88rem;
    border-radius: 0.88rem;
    background: var(--main-color);
    font-size: 0.32rem;
    color: #fff;
    text-align: center;
    line-height: 0.88rem;
    margin: 0 auto;
  }
}

</style>
