var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "apply-success" }, [
    _c(
      "div",
      { staticClass: "icon" },
      [
        _c("icon-svg", {
          staticClass: "main-color success-svg",
          attrs: { "icon-class": "yi_icon_xuanze" },
        }),
      ],
      1
    ),
    _c("p", { staticClass: "title" }, [_vm._v("您的申请已提交")]),
    _c("p", { staticClass: "tip" }, [_vm._v("请耐心等待")]),
    _c("div", { staticClass: "btn", on: { click: _vm.lookApply } }, [
      _vm._v("\n    查看申请\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }